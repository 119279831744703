import React from 'react';
import Layout, { TEMPLATE } from '../components/Layout';

const title = 'Page Not Found';

const ErrorPage = () => (
  <Layout title={title} template={TEMPLATE.TEXT}>
    <h1>Error 404: {title}</h1>
  </Layout>
);

export default ErrorPage;
